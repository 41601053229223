<template>
    <div class='goodsOrders'>
        <div class="planlist">
            <el-row style="margin-bottom:1rem">
                <el-col :span="24" style="text-align:left">
                    <!-- <el-input style="width:200px;" v-model="paginationInfo.condition.mobile" maxlength="11"
                        placeholder="请输入手机号" clearable></el-input> -->
                    <el-date-picker v-model="paginationInfo.condition.begin_date" align="right" type="date"
                        placeholder="选择开始时间" :picker-options="{
                            disabledDate(time) {
                                return time.getTime() > Date.now();
                            },
                        }">
                    </el-date-picker>
                    <el-date-picker v-model="paginationInfo.condition.end_date" align="right" type="date"
                        placeholder="选择结束时间" :picker-options="{
                            disabledDate(time) {
                                return time.getTime() > Date.now();
                            },
                        }">
                    </el-date-picker>
                    <el-select v-model="paginationInfo.condition.app_code" placeholder="请选择订单状态" @change="queryList"
                        filterable clearable>
                        <el-option label="language会员" value="languagemember"></el-option>
                        <el-option label="冰蓝科技" value="blkjwechatpay"></el-option>
                        <el-option label="陀螺领购" value="tllgwechatpay"></el-option>
                    </el-select>
                    <el-button @click="queryList">查询</el-button>

                    <el-input style="width:200px;margin-left:20px" v-model="formData.complaint_id" placeholder="请输入投诉ID"
                        clearable></el-input>
                    <el-input style="width:200px;" v-model="formData.out_trade_no" placeholder="请输入订单编码"
                        clearable></el-input>
                    <el-button type="primary" plain @click="handleTk(formData)">退款</el-button>
                </el-col>
            </el-row>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <el-form>
                            <el-form-item label="支付手机号编码:">
                                <div style="word-break:break-all">{{ props.row.payer_phone }}</div>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column prop="complaint_id" label="投诉ID" align='center' width="150">
                </el-table-column>
                <el-table-column label="投诉时间" align='center' width="150">
                    <template slot-scope="scope">{{ $moment(scope.row.complaint_time).format('YYYY-MM-DD HH:mm:ss')
                    }}</template>
                </el-table-column>
                <el-table-column prop="user_complaint_times" label="投诉次数" align='center' width="80">
                </el-table-column>
                <el-table-column prop="complaint_detail" label="投诉详情" align='center' width="200">
                </el-table-column>
                <el-table-column prop="problem_description" label="投诉问题描述" align='center' width="160">
                </el-table-column>
                <el-table-column label="投诉订单信息" align='center'>
                    <template slot-scope="scope">
                        <div v-for="(item, i) in scope.row.complaint_order_info" :key="i">
                            <div>订单{{ i + 1 }}</div>
                            <div>金额： {{ item.amount / 100 }}</div>
                            <div>订单编码： {{ item.out_trade_no }}</div>
                            <div>交易序列号： {{ item.transaction_id }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="投诉全额退款" align='center' width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.complaint_full_refunded == false">否</span>
                        <span v-else-if="scope.row.complaint_full_refunded == true">是</span>
                    </template>
                </el-table-column>
                <el-table-column label="投诉状态" align='center' width="100">
                    <template slot-scope="scope">
                        <span v-if="scope.row.complaint_state == 'PROCESSING'">处理中</span>
                        <span v-else-if="scope.row.complaint_state == 'PENDING'">待处理</span>
                        <span v-else-if="scope.row.complaint_state == 'PROCESSED'">处理完</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align='center' width="150">
                    <template slot-scope="scope">
                        <el-button type="primary" plain :disabled="scope.row.complaint_state == 'PROCESSED'"
                            @click="handleTk({ out_trade_no: scope.row.complaint_order_info[0].out_trade_no, complaint_id: scope.row.complaint_id })">退款</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="paginationInfo.pageNo" :page-sizes="[10, 20, 50, 100]" :page-size="paginationInfo.pageSize"
                :total="paginationInfo.total" layout="total, sizes, prev, pager, next, jumper">
            </el-pagination>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            isHandleL: false,
            formData: {
                complaint_id: '',
                out_trade_no: '',
            },
            paginationInfo: {
                pageSize: 10,
                total: 0,
                pageNo: 1,
                condition: {
                    begin_date: "",
                    end_date: "",
                    app_code: "languagemember",
                    // mobile: '',
                    // status: '',
                }
            },
            tableData: [],
        }
    },
    computed: {},
    watch: {},
    created() {
        this.paginationInfo.condition.begin_date = this.$moment(new Date()).format('YYYY-MM-DD');
        this.paginationInfo.condition.end_date = this.$moment(new Date()).format('YYYY-MM-DD');
        this.getManagerComplaints()
    },
    mounted() {
    },
    methods: {
        handleTk(info) {
            this.$confirm('是否确认退款？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.isHandleL = true;
                this.$service.OnlineOrderService
                    .handleTk(info)
                    .then(res => {
                        this.isHandleL = false;
                        if (res.data.code === 0) {
                            this.getManagerComplaints();
                            this.$message({
                                message: '退款成功！',
                                type: 'success'
                            });
                        } else {
                            this.$message({
                                message: res.data.message,
                                type: 'error'
                            });
                        }
                    }).catch((err) => {
                        this.isHandleL = false;
                        window.console.log(err)
                        this.$message({
                            message: '网络错误，请重新修改！',
                            type: 'error'
                        });
                    })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        queryList() {
            this.paginationInfo.pageNo = 1;
            this.getManagerComplaints()
        },
        handleSizeChange(val) {
            window.console.log(`每页 ${val} 条`);
            this.paginationInfo.pageSize = val;
            this.paginationInfo.pageNo = 1;
            this.getManagerComplaints();
        },
        handleCurrentChange(val) {
            window.console.log(`当前页: ${val}`);
            this.paginationInfo.pageNo = val;
            this.getManagerComplaints();
        },
        getManagerComplaints() {
            this.$service.OnlineOrderService
                .getManagerComplaints(this.paginationInfo)
                .then(res => {
                    if (res.data.code === 0) {
                        let dataInfo = res.data.data;
                        this.paginationInfo.total = dataInfo.total_count || 1000;
                        this.tableData = dataInfo.data;
                    }
                })
        },
    },
    beforeCreate() { }, // 生命周期 - 创建之前
    beforeMount() { }, // 生命周期 - 挂载之前
    beforeUpdate() { }, // 生命周期 - 更新之前
    updated() { }, // 生命周期 - 更新之后
    beforeDestroy() { }, // 生命周期 - 销毁之前
    destroyed() { }, // 生命周期 - 销毁完成
    activated() { } // 如果页面有keep-alive缓存功能,这个函数会触发
}
</script>
<style lang='less' scoped>
.goodsOrders {}
</style>